import axios from 'axios';
import { message } from '../axios/message';
import router from '../router/index'
const requests = axios.create({
    baseURL: "https://ar.sz-ipc.com:8090",
    // headers: {
    //     Authorization: `${localStorage.getItem("token")}`
    // }
})

requests.interceptors.request.use(config => {
    // config.headers['Authorization'] = `${localStorage.getItem("token")}`;
    return config;
}, error => {
    console.log(error);
    return Promise.reject(new Error("faile"));
})

requests.interceptors.response.use(response => {
    if (response.data.status == 401) {
        message({
            message: '用户未登录',
            type: 'error'
        })
        router.push({
            name: "UserLogin"

        });
    } else if (response.data.status == 403) {
        message({
            message: '无权执行此操作',
            type: 'error'
        })
    } else {
        if (response.data.desc != null) {
            message({
                showClose: true,
                message: response.data.desc,
                type: 'success'
            });
        }
    }
    return response;
}, error => {
    // console.log(error.response.data.path);
    // var messageInfo = "未知错误"
    // switch (error.response.data.path) {
    //     case "/api/user/login":
    //         messageInfo = "手机号未注册或验证码有误";
    //         break
    // }
    // message({
    //     message: messageInfo,
    //     type: 'error'
    // })
    // if (error.response.status === 401) {
    //     router.push({
    //         name: "UserLogin"
    //     });
    // }
    return Promise.reject(new Error("faile"));
})

export default requests;