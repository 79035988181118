<template>
  <div id="app">
    <!-- <div id="container"></div> -->
    <video id="video" style="width: 540px;height: 540px;display: none;" webkit-playsinline="true" x-webkit-airplay="true"
      playsinline="true" x5-video-player-type="h5" x5-video-orientation="h5" x5-video-player-fullscreen="true"
       muted loop></video>
    <video style="width: 100%;height: 540px;position: absolute;z-index: 10;left: 0;margin: auto;" webkit-playsinline="true"
      x-webkit-airplay="true" playsinline="true" x5-video-player-type="h5" x5-video-orientation="h5"
      x5-video-player-fullscreen="true" src="http://120.234.47.242:9000/infohelper/sceneWithoutPanada.mp4" autoplay muted
      loop></video>
  </div>
</template>
<script>
import * as THREE from 'three'
export default {
  name: 'THREETest',
  methods: {
    renderer: null,
    video3D:null
  },
  mounted() {
    this.video3D=this.$route.params.content
    console.log(this.video3D);
    // alert(this.video3D)
    let videoWidth = 1000; //视频实际的宽度
    let videoHeight = 1000;//原视频实际的高度的一般
    // THREE.TextureLoader
    //定义渲染器
    var renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(videoWidth, videoHeight);
    renderer.autoClear = true;
    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer = renderer.domElement
    renderer.domElement.style.position = "absolute"
    renderer.domElement.style.top = "0"
    renderer.domElement.style.left = "0"
    renderer.domElement.style.right = "0"
    renderer.domElement.style.bottom = "0"
    renderer.domElement.style.width = "100%"
    renderer.domElement.style.height = "500px"
    renderer.domElement.style.margin = "auto"
    renderer.domElement.style.zIndex = 15
    document.body.appendChild(renderer.domElement);
    var scene = new THREE.Scene();
    var camera = new THREE.Camera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
    scene.add(camera);
    //播放视频
    var video = document.getElementById("video");
    video.src=require('../assets/pandaInRed.mp4')
    // video.onended = () => {
    //   video.play();
    // }
    //获取视频纹理
    var texture = new THREE.VideoTexture(video);
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBAFormat;

    //定义几何体
    var geometry = new THREE.PlaneGeometry(2, 1);

    //处理视频纹理
    var uniforms = {
      // time: { type: "f", value: 1.0 },
      videoTexture: { value: texture },
      threshold: { value: 0.05 }
    };
    var material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      vertexShader:
        `varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
      fragmentShader:
        `
    uniform sampler2D videoTexture;
	  uniform float threshold;
	  varying vec2 vUv;
	  void main() {
	 	  vec4 color = texture2D(videoTexture, vUv);
	 	  float greenScreen = color.g - max(color.r, color.b);
	 	  float alpha = 1.0 - smoothstep(threshold - 0.05, threshold + 0.05, greenScreen);
	 	  gl_FragColor = vec4(color.rgb, alpha);
    }`,

      // 通过视频的rgb值，转换成webGl渲染需要的rgba值
      // vec4是取样器获取的是值对应的是(r,g,b,a)
      // texture2D是取样器获取的是视频的rgb值，可以通过.r,.g,.b或者.rg,.rb,.gb,.rgb分别取样
      // vUv传入的是纹理的坐标（浮点数）
      side: THREE.DoubleSide,
      transparent: true
    });
    var mesh = new THREE.Mesh(geometry, material)
    scene.add(mesh);


    var animate = function () {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };

    // document.getElementById('play-button').addEventListener('click', e => {
    //   video.play();
    //   requestAnimationFrame(animate);
    // });
    video.play();
    requestAnimationFrame(animate);
  },
  beforeDestroy() {
document.body.removeChild(this.renderer)
  }
}
</script>
<style scoped>
#app {
  width: 100%;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  background: url('../assets/background.png');
  align-items: center;
  overflow: auto;
  justify-content: center;
}
</style>