import Vue from 'vue'
import VueRouter from 'vue-router'
import contentCom from '../views/contentCom.vue'
import indexCom from '../views/indexCom.vue'
import GetGoodsInfoCom from '../views/GetGoodsInfoCom.vue'
import CameraCom from '../views/CameraCom.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//解决vue路由重复导航错误
//获取原型对象上的push函数
const routes = [
  {
    path: '/',
    redirect: '/CameraCom'
  },
  {
    path: '/contentCom:content',
    name: 'contentCom',
    component: contentCom
  },
  {
    path: '/GetGoodsInfoCom:content',
    name: 'GetGoodsInfoCom',
    component: GetGoodsInfoCom,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/CameraCom',
    name: 'CameraCom',
    component: CameraCom
  }
]

const router = new VueRouter({
  routes
})

export default router
