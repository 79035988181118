<template>
    <div id="app">
        <div id="tip" v-show="androidAndWx"></div>
        <video v-show="!searchSuccess"
            style="width:90%;height:540px;position: absolute;z-index: 10;left: 0;top:0;right:0;bottom:0;margin: auto;"
            webkit-playsinline="true" x-webkit-airplay="true" playsinline="true" x5-video-player-type="h5"
            x5-video-orientation="h5" x5-video-player-fullscreen="true" id="preview" autoplay muted></video>

        <video id="video" style="width: 540px;height: 540px;display: none;" webkit-playsinline="true"
            x-webkit-airplay="true" playsinline="true" x5-video-player-type="h5" x5-video-orientation="h5"
            x5-video-player-fullscreen="true" muted loop></video>
        <canvas style="display: none;" id="canvasCamera" class="canvas" :width="400" :height="400"></canvas>
        <div v-if="searchSuccess && emptyRes < 2"
            style="display: flex;flex-direction: column;width: 100%;align-items: center;margin-top: 40%;">
            <div style="width: 90%;color: white;">为您找到以下关联产品</div>
            <div v-for="item, index in productArr" :key="index" @click="getGoodInfoById(item.id)"
                style="margin: 10px 0; background-color: white;border-radius: 20px;width: 90%;display: flex;justify-content: space-between;align-items: center;padding: 5px 0;">
                <div style="margin-left: 20px;">
                    {{ item.productName }}
                </div>
                <div style="margin-right: 20px;">
                    >
                </div>
            </div>
        </div>
        <el-empty description="" v-if="emptyRes >= 2" style="margin-top: 200px;"></el-empty>
        <div v-if="emptyRes >= 2" style="color: white;">未匹配到任何产品或图片，可尝试重新匹配</div>
        <el-button v-if="searchSuccess" @click="restartCScan" type="primary" style="margin: 20px;">重新匹配</el-button>
        <div v-if="!searchSuccess"
            style="color: white;width: 90%;display: flex;justify-content: center;align-items: center;flex: 1;font-size: x-large;">
            {{ indexInfo }}
        </div>
    </div>
</template>
  
<script>
import * as THREE from 'three'
import request from "../axios/request";
export default {
    data() {
        return {
            productArr: [],
            thisContext: null,
            thisCancas: null,
            preview: null,
            videoWidth: 4096,
            videoHeight: 2160,
            timer: null,
            searchSuccess: false,
            indexInfo: null,
            userId: null,
            baseURL: null,
            socket: null,
            show3D: false,
            getInfoTime: 1,
            curInfoTime: 0,
            androidAndWx: false,
            emptyRes: 0,
            renderer: null
        }
    },
    methods: {
        restartCScan() {
            this.searchSuccess = false
            this.timer = setInterval(this.automaticComparison, 5000)
            this.emptyRes = 0
            this.show3D = false
        },
        show3Dobject() {
            let videoWidth = 1000; //视频实际的宽度
            let videoHeight = 1000;//原视频实际的高度的一般
            var renderer = new THREE.WebGLRenderer({
                antialias: true,
                alpha: true
            });
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(videoWidth, videoHeight);
            renderer.autoClear = true;
            renderer.toneMapping = THREE.ACESFilmicToneMapping;
            renderer.domElement.style.position = "absolute"
            renderer.domElement.style.top = "0"
            renderer.domElement.style.left = "0"
            renderer.domElement.style.right = "0"
            renderer.domElement.style.bottom = "0"
            renderer.domElement.style.width = "100%"
            renderer.domElement.style.height = "500px"
            renderer.domElement.style.margin = "auto"
            renderer.domElement.style.zIndex = 15
            renderer.domElement.style.display = "none"
            document.body.appendChild(renderer.domElement);
            this.renderer = renderer.domElement
            var scene = new THREE.Scene();
            var camera = new THREE.Camera(45, window.innerWidth / window.innerHeight, 0.1, 1000);
            scene.add(camera);
            //播放视频
            var video = document.getElementById("video");
            video.src = require('../assets/pandaInRed.mp4')
            // video.onended = () => {
            //   video.play();
            // }
            //获取视频纹理
            var texture = new THREE.VideoTexture(video);
            texture.minFilter = THREE.LinearFilter;
            texture.magFilter = THREE.LinearFilter;
            texture.format = THREE.RGBAFormat;

            //定义几何体
            var geometry = new THREE.PlaneGeometry(5, 2);

            //处理视频纹理
            var uniforms = {
                // time: { type: "f", value: 1.0 },
                videoTexture: { value: texture },
                threshold: { value: 0.05 }
            };
            var material = new THREE.ShaderMaterial({
                uniforms: uniforms,
                vertexShader:
                    `varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }`,
                fragmentShader:
                    `
    uniform sampler2D videoTexture;
	  uniform float threshold;
	  varying vec2 vUv;
	  void main() {
	 	  vec4 color = texture2D(videoTexture, vUv);
	 	  float greenScreen = color.g - max(color.r, color.b);
	 	  float alpha = 1.0 - smoothstep(threshold - 0.05, threshold + 0.05, greenScreen);
	 	  gl_FragColor = vec4(color.rgb, alpha);
    }`,

                // 通过视频的rgb值，转换成webGl渲染需要的rgba值
                // vec4是取样器获取的是值对应的是(r,g,b,a)
                // texture2D是取样器获取的是视频的rgb值，可以通过.r,.g,.b或者.rg,.rb,.gb,.rgb分别取样
                // vUv传入的是纹理的坐标（浮点数）
                side: THREE.DoubleSide,
                transparent: true
            });
            var mesh = new THREE.Mesh(geometry, material)
            scene.add(mesh);


            var animate = function () {
                requestAnimationFrame(animate);
                renderer.render(scene, camera);
            };
            video.play();
            requestAnimationFrame(animate);
        },
        initWs(userId) {
            if (typeof (WebSocket) === "undefined") {
                alert("您的浏览器不支持socket")
            } else {
                var wsUrl = this.baseURL.replace("https://", "")
                // 实例化socket 111是固定的用户id,正式环境直接获取当前登录用户id
                this.socket = new WebSocket("wss:" + wsUrl + "//wsServer/" + userId)
                this.global.setWs(this.socket)
                // 监听socket连接
                this.socket.onopen = () => {
                    console.log("socket连接成功")
                }
                // 监听socket错误信息
                this.socket.onerror = () => {
                    console.error("连接错误")
                }
                //监听socket消息
                this.socket.onmessage = (msg) => {
                    var resData = msg.data.split(":\"")[1]
                    resData = resData.replace("\"}", "")
                    // console.log(resData);
                    if (resData.indexOf("product") != -1) {
                        var getInfoTimeFromJava = resData.split("?")[0]
                        if (getInfoTimeFromJava != this.curInfoTime) {
                            this.productArr = []
                            this.curInfoTime = getInfoTimeFromJava
                            this.searchSuccess = true
                            this.renderer.style.display = "none"
                            this.closeTimer()
                        }
                        resData = resData.split("?")[1]
                        var propArr = resData.split(",")
                        var productId = propArr[0].split("=")[1]
                        var productName = propArr[1].split("=")[1]
                        this.productArr.push({
                            id: productId,
                            productName: productName
                        })
                    } else if (resData.indexOf("assets") != -1) {
                        console.log("获取到图像");
                        if (this.show3D == false) {
                            console.log("渲染3D视频");
                            this.renderer.style.display = "block"
                            this.show3D = true
                        }

                        // console.log("视频地址无需处理");
                    } else if (resData.indexOf("emptyList") != -1) {
                        this.emptyRes++
                        if (this.emptyRes == 2) {
                            this.closeTimer()
                            this.searchSuccess = true
                            this.renderer.style.display = "none"
                        }
                    }
                }
                // 监听socket关闭信息
                this.socket.onclose = (e) => {
                    console.log("socket已经关闭")
                }
            }
        },
        closeTimer() {
            console.log("定时器被暂停");
            clearInterval(this.timer)
        },
        async getGoodInfoById(id) {
            this.$router.push({
                name: 'GetGoodsInfoCom',
                params: {
                    content: id,
                },
            });
        },
        async automaticComparison() {
            this.getInfoTime++
            this.thisContext.drawImage(this.preview, 0, 0, 400, 400);
            // 获取图片base64链接
            await request({
                url: "/api/product/getProductInfo",
                method: "POST",
                data: {
                    base64Code: this.thisCancas.toDataURL('image/png'),
                    userId: this.userId,
                    getInfoTime: this.getInfoTime
                }
            });
        },
        closeWebsocket() {
            if (this.socket != null) {
                this.socket.close()
                this.socket = null
                console.log("socket已关闭");
            }
        },
        iswx() {
            var ua = navigator.userAgent.toLowerCase();
            var isWeixin = ua.indexOf('micromessenger') != -1;
            if (isWeixin) {
                return true;
            } else {
                return false;
            }
        },
        async initPage() {
            this.baseURL = request.defaults.baseURL
            var resOfGettingUserId = await request({
                url: "/api/product/getUserId",
                method: "GET"
            });
            if (resOfGettingUserId.data.status == 200) {
                console.log("获取userId成功");
                this.userId = resOfGettingUserId.data.data
                this.initWs(this.userId)
            }
            this.thisCancas = document.getElementById('canvasCamera');
            this.thisContext = this.thisCancas.getContext('2d');
            this.preview = document.getElementById("preview");
            var that = this;
            navigator.mediaDevices
                .getUserMedia({
                    audio: false,
                    video: { facingMode: { exact: "environment" } }
                    // video: true
                })
                .then((stream) => {
                    that.preview.srcObject = stream;
                    that.preview.play()
                    that.indexInfo = "扫描文字获取对应产品信息"
                })
                .catch((err) => {
                    console.log("recording error: ", err);
                });
            this.timer = setInterval(this.automaticComparison, 3000)
        }
    },
    async mounted() {
        var isWx = this.iswx()
        if (isWx) {
            // 提示跳转页面
            this.androidAndWx = true
        } else {
            this.show3Dobject()
            this.initPage()
        }
    },
    beforeDestroy() {
        this.closeTimer()
        this.preview.srcObject.getTracks().forEach((track) => track.stop());
        this.closeWebsocket()
    }
}
</script>
  
<style scoped>
#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    background: url('../assets/background.png');
    align-items: center;
    overflow: auto;
    /* justify-content: center; */
}

#tip {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    background: url("../assets/android.png") center center / contain no-repeat;
    z-index: 10;
    background-color: rgb(64, 62, 62);
    /* opacity: 0.9; */
}
</style>
  