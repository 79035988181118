<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    console.log(this.$route.path);
    console.log("这里是app.vue");
  }
}
</script>

<style scoped></style>
