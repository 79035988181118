<template>
    <div id="app">
        <div v-if="productInfo.pictureCase != null && productInfo.pictureCase.length > 0"
            style="margin: 10px 0;color: white;">相关案例</div>
        <el-carousel v-if="productInfo.pictureCase != null && productInfo.pictureCase.length > 0" :interval="4000"
            type="card" height="150px" style="margin-bottom: 10px;width: 95%;">
            <el-carousel-item v-for="item, index in productInfo.pictureCase" :key="index">
                <img :src="item" style="width: 100%;height: 100%;" />
            </el-carousel-item>
        </el-carousel>
        <iframe v-show="productInfo.GoodUrl != null" :src="productInfo.GoodUrl"
            style="border: none;flex:1;width: 100%;"></iframe>
    </div>
</template>
<script>
import request from "../axios/request";
export default {
    data() {
        return {
            productInfo: {
                GoodUrl: null,
                pictureCase: null
            }
        }
    },
    methods: {
        reIdentification() {
            this.$router.go(-1)
        }
    },
    async mounted() {
        var id = this.$route.params.content
        let resOfGettingProductInfoById = await request({
            url: "/api/product/getProductInfoById/" + id,
            method: "POST"
        });
        if (resOfGettingProductInfoById.data.status == 200) {
            this.productInfo.GoodUrl = resOfGettingProductInfoById.data.data.goodUrl
            this.productInfo.pictureCase = resOfGettingProductInfoById.data.data.pictureCase
            this.successGetRes = true
        }
    },
    beforeDestroy() {

    }
}
</script>
<style scoped>
#app {
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    background: url('../assets/background.png');
    align-items: center;
    overflow: visible;
    /* justify-content: center; */
}
</style>