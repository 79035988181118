<template>
    <div v-loading="loading" element-loading-text="图片上传中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)" class="backClass"
        style="width: 100%;height: 100%;display: flex;flex-direction: column;align-items: center;">
        <div style="color: white;font-size: xx-large;margin-top: 20px;">上传单人正脸照片</div>
        <div style="color: #9DB5E4;margin-top: 10px;">获取您的专属 XR 虚拟拍摄视频</div>
        <div ref="upload" class="avatar-uploader" @click="openCamera">
            <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
        </div>
        <el-upload v-show="false" ref="upload" class="avatar-uploader" :action="baseURL + '/api/file/upload/'" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
        </el-upload>
        <div style="color: #9DB5E5;width: 70%;margin-bottom: 50px;font-size: xx-small;">友情提示：为确保最佳的匹配效果，请上传您的正脸
            照片。拍摄时，请勿佩戴口罩。请注意，非正面角度
            和小尺寸人物照片可能会降低匹配的精确度。</div>

        <div class="btnClass" @click="uploadMyPic">点击拍摄</div>
        <div style="color: #9DB5E4;width: 70%;margin-top: 20px;font-size: xx-small;">*拍摄的照片仅用于人脸匹配，并保证用户隐私安全。</div>
    </div>
</template>

<script>
import request from "../axios/request";
export default {
    data() {
        return {
            baseURL: null,
            imageUrl: '',
            loading: false
        }
    },
    methods: {
        uploadMyPic() {
            this.$refs['upload'].$children[0].$refs.input.click();
        },
        handleAvatarSuccess(res, file) {
            this.loading = false
            this.$router.push({
                name: 'GetGoodsInfoCom',
                params: {
                    content: res.data,
                },
            });
        },
        beforeAvatarUpload(file) {
            this.loading = true
            const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
            // const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.error('上传头像图片只能是 图片 格式!');
            }
            // if (!isLt2M) {
            //     this.$message.error('上传头像图片大小不能超过 2MB!');
            // }
            return isJPG;
        },
        openCamera(){
            this.$router.push({
                name: 'CameraCom'
            });
        }
    },
    created() {
        this.baseURL = request.defaults.baseURL;
        console.log(this.baseURL);
    }
}
</script>

<style scoped>
.btnClass {
    height: 50px;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url("../assets/button.png") center center no-repeat;
    background-size: contain;
    color: white;
    font-size: larger;
    font-weight: 700;
}

.backClass {
    background: url('../assets/background.png');
}

.avatar-uploader  {
    border: 1px dashed #2E57C8;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background-color: white;
    width: 300px;
    height: 300px;
    background: url("../assets/round.png") 200% center / contain no-repeat, url("../assets/head.png") center center / 25% 30% no-repeat;
    /* background-size: contain; */
    /* box-shadow: 0 10px 5px 0 rgba(237, 239, 241, 0.35); */
}


</style>